import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import 'normalize.css';
import '../reset.css';

const QB_API = '/api';

async function qb() {
  const response = await fetch(QB_API);
  const body = await response.json();

  if (response.status !== 200) throw Error(body.message);
  return body;
}

const ScrambleStyles = styled.ul`
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  max-width: 800px;
  flex-wrap: wrap;
  margin: 0 auto;
  li {
    font-size: 30px;
    width: 200px;
    height: 100px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    &:nth-child(even) {
      background: #ddd;
    }
  }
`;

const Scramble = ({ scramble }) => (
  <ScrambleStyles>
    {scramble.split(' ').map(move => (
      <li key={Math.random()}>{move}</li>
    ))}
  </ScrambleStyles>
);

const App = () => {
  const [scramble, setScramble] = useState('qb');

  useEffect(() => {
    if (scramble === 'qb') {
      const savedScramble = localStorage.getItem('nextScramble');
      if (savedScramble) {
        setScramble(savedScramble);
        qb().then(res => localStorage.setItem('nextScramble', res.q));
      } else {
        Promise.all([qb(), qb()]).then(([{ q: firstScramble }, { q: nextScramble }]) => {
          setScramble(firstScramble);
          localStorage.setItem('nextScramble', nextScramble);
        });
      }
    }
  });

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.keyCode === 32) {
        setScramble(localStorage.getItem('nextScramble'));
        qb().then(res => localStorage.setItem('nextScramble', res.q));
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  return <Scramble scramble={scramble} />;
};

Scramble.propTypes = {
  scramble: string.isRequired,
};

export default App;
